<template>

    <form v-if="inputData && form" class="space-y-6 w-full">

        <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div class="col-span-1">
                <Input name="name" :placeholder="form.type.id === 2 ? 'Vārds Uzvārds' : 'Nosaukums'" v-model="form.name"
                       :errors="errors.name"/>
            </div>

            <template v-if="customer.type && customer.type.id === 1">
                <div class="col-span-1">
                    <Select v-model="form.companyForm" :items="inputData.company_forms" :errors="errors.companyForm"
                            placeholder="Uzņēmuma forma"/>
                </div>
            </template>

            <div class="col-span-1">
                <Input name="reg_nr" :placeholder="customer.type.id === 1 ? 'Reģistrācijas nr.' : 'PK'"
                       v-model="form.reg_nr" :errors="errors.reg_nr"/>
            </div>

            <template v-if="customer.type.id === 1">
                <div class="col-span-1">
                    <Input name="vat_nr" placeholder="PVN nr." v-model="form.vat_nr" :errors="errors.vat_nr"/>
                </div>
            </template>


            <div class="col-span-1">
                <Input name="address" :placeholder="customer.type.id === 1 ? 'Juridiskā adrese' : 'Adrese'"
                       v-model="form.address" :errors="errors.address"/>
            </div>

          <div class="col-span-1">
            <Autocomplete placeholder="Valsts" v-model="form.country" :errors="errors.country"
                          :items="countries" :showItems="true" :cleanable="false" />
          </div>

            <div class="col-span-1">
                <Input name="postpay_days" placeholder="Pēcapmaksas dienas" v-model="form.postpay_days" :errors="errors.postpay_days"/>
            </div>

            <div class="col-span-1">
                <Input name="phone" placeholder="Telefona nr." v-model="form.phone" :errors="errors.phone"/>
            </div>

            <div class="col-span-1">
                <Input name="email" placeholder="E-pasts" v-model="form.email" :errors="errors.email"/>
            </div>

            <div class="col-span-2">
                <Input name="invoice_emails" placeholder="E-pasti rēķiniem" v-model="form.invoice_emails" :errors="errors.invoice_emails"/>
            </div>

            <div class="col-span-1">
                <Select v-model="form.manager" :items="inputData.managers" :errors="errors.manager"
                        placeholder="Menedžeris"/>
            </div>


            <div class="col-span-1">
                <Checkbox class="md:mt-8" text="Eksportam" v-model="form.is_export"/>
            </div>

            <div class="col-span-1">
                <Checkbox class="md:mt-8" text="Atktīvs" v-model="form.is_active"/>
            </div>

        </div>

        <div class="md:flex-shrink-0 flex items-center flex-wrap ml-auto gap-3 justify-end pt-4">
            <template v-if="!loading">
                <button type="button"
                        class="flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gradient-to-r from-button2-from to-button2-to hover:from-button2-to hover:to-button2-from focus:outline-none focus:border-none transition duration-500 ease-in-out"
                        @click.prevent="submit">
                    Saglabāt
                </button>
            </template>
            <template v-else>
                <Loading/>
            </template>

            <button @click.prevent="hideEditCustomerForm" type="button"
                    class="inline-flex items-center px-4 py-2 border border-gray-300 dark:border-gray-500 shadow-sm text-sm font-medium rounded-md text-gray-700 dark:text-gray-300 bg-white dark:bg-gray-750 hover:bg-gray-50 dark:hover:bg-gray-770 focus:outline-none focus:ring-0">
                Atcelt
            </button>

        </div>

    </form>

</template>

<script>
import {mapGetters} from "vuex";
import Select from "@/components/Components/Select";
import Input from "@/components/Components/Input";
import Validation from "@/modules/Validation";
import Loading from "@/components/Components/Loading";
import Checkbox from "../../Components/Checkbox";
import Autocomplete from "@/components/Components/Autocomplete";

export default {
    name: "EditCustomerDetails",
    data: () => ({
        form: null,
    }),
    props: ["customer"],
    components: {
        Input,
        Select,
        Loading,
        Checkbox,
        Autocomplete,
    },
    created() {
        if (!this.countries) {
            this.$store.dispatch('fetchCountries');
        }
    },
    mounted() {
        this.$store.dispatch("setErrors", {});
        this.$store.dispatch("getCustomerInputData");
        this.form = this.customer
    },
    computed: {
        ...mapGetters({
            inputData: "customerInputData",
            countries: "fetchedCountries",
            loading: "loading",
            errors: 'errors',
        }),
        formValidation() {
            return {
                name: {
                    rules: ['required']
                },
                country: {
                  rules: ['required']
                },
                reg_nr: {
                    rules: [this.customer.type.id === 1 ? 'required' : null]
                },
                address: {
                    rules: ['required']
                },
                email: {
                    rules: ['email']
                },
            }
        },
    },
    methods: {
        hideEditCustomerForm() {
            this.$store.dispatch("setErrors", {});
            this.$store.dispatch("getCustomer", this.customer.id);
            this.$store.dispatch("removeAllFormsForDisplay");
        },
        submit() {
            this.$Progress.start();

            if (Validation(this.formValidation, this.form)) {
                this.$store.dispatch("updateCustomer", {
                    id: this.form.id,
                    name: this.form.name,
                    address: this.form.address,
                    country_id: this.form.country ? this.form.country.id : null,
                    reg_nr: this.form.reg_nr,
                    vat_nr: this.form.vat_nr,
                    postpay_days: this.form.postpay_days,
                    phone: this.form.phone,
                    email: this.form.email,
                    invoice_emails: this.form.invoice_emails,
                    is_active: this.form.is_active,
                    is_export: this.form.is_export,
                    company_form_id: this.form.companyForm ? this.form.companyForm.id : null,
                    managing_user_id: this.form.manager
                        ? this.form.manager.id
                        : null,
                });
            } else {
                this.$Progress.fail();
            }
        },
    },
}
</script>

<style>

</style>